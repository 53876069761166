import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

// import { css } from "@emotion/core"

// const skillsArray = [
//     "Tournage", "Réalisation", "Montage", "Etalonnage", 
//     "Animation", "3D", "Motion graphics", "VFX", 
//     "Vidéo 360", "Live Streaming", "Drone", 
//     "Photographie", "Retouche", "Social Media Video", 
//     "Web Marketing", "Video Ads", "Storytelling", "Graphisme",
//     "Développement Web", "Dev Wordpress", "Web design", "UX", "UI",
//     "Analytics & Tracking", "Dashboarding",
// ]

const Form = (props) => {
    const {
        formName,
        sendBtnText,
        freelance,
    } = props

    const data = useStaticQuery(graphql`
        query AllServicesQuery {
            allProductsYaml(sort: {fields: name, order: ASC}) {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
        }
    `)

    // console.log("SKILLS", data)

    return (
        <form name={formName} data-netlify="true" method="post" netlify-honeypot="bot-field" action="/merci">
            <div className="form-container">
                <label htmlFor="first-name" className="field half-width half-width-left">
                    <span>Prénom</span>
                    <input id="first-name" type="text" name="first-name" aria-label="first-name" placeholder="Emilie" required />
                </label>

                <label htmlFor="last-name" className="field half-width half-width-right">
                    <span>Nom</span>
                    <input id="last-name" type="text" name="last-name" aria-label="last-name" placeholder="Duvalais" required />
                </label>

                <label htmlFor="email" className="field half-width half-width-left">
                    <span>Email</span>
                    <input id="email" type="email" name="email" aria-label="email" placeholder="emilie@duvalais.ch" required />
                </label>

                <label htmlFor="phone" className="field half-width half-width-right">
                    <span>Votre téléphone</span>
                    <input id="phone" type="phone" name="phone" aria-label="phone" placeholder="0791234567" required />
                </label>

                <label className="field half-width half-width-left" htmlFor="localisation">
                    <span>Votre localisation (où êtes-vous basé.e ?)</span>
                    <input id="localisation" type="text" name="localisation" aria-label="localisation" placeholder="Lausanne" required/>
                </label>

                {freelance &&
                    <label className="field half-width half-width-right" htmlFor="years-experience">
                        <span>Combien d'années d'expérience avez-vous ?</span>
                        <input id="years-experience" type="number" name="years-experience" aria-label="years-experience" placeholder="5" min="1" max="100" required/>
                    </label>
                }

                {freelance &&
                    <label className="field full-width" htmlFor="cv">
                        <span>Votre CV (LinkedIn ou lien vers site)</span>
                        <input id="cv" type="url" name="cv" aria-label="cv" placeholder="ch.linkedin.com/in/emilie-duvalais" required/>
                    </label>
                }

                <label className="field full-width" htmlFor="portfolio">
                    <span>Votre portfiolio (lien vers votre site / youtube ...)</span>
                    <input id="portfolio" type="url" name="portfolio" aria-label="portfolio" placeholder="https://duvalais-prod.ch" required/>
                </label>

                <div className="checkboxes" htmlFor="business-area">
                <span>Dans quel(s) canton(s) romands exercez-vous votre activité ?</span>

                {["Genève", "Vaud", "Valais", "Fribourg", "Neuchâtel", "Berne", "Jura"].map( (canton, index) => (
                    <div key={canton}>
                        <input type="checkbox" id={`canton-${index+1}`} name={`canton-${index+1}`} value={canton} />
                        <label htmlFor={`canton-${index+1}`}>{canton}</label>
                    </div>
                ))}
                </div>

                <div className="checkboxes" htmlFor="skills">
                <span>Quelles sont vos compétences ?</span>

                {data.allProductsYaml.edges.map( ({node}, index) => (
                    <div key={node.id}>
                        <input type="checkbox" id={`skill-${index+1}`} name={`skill-${index+1}`} value={node.id} />
                        <label htmlFor={`skill-${index+1}`}>{node.name}</label>
                    </div>
                ))}
                </div>

                <label className="field full-width" htmlFor="top-skill">
                    <span>Quelle est votre spécialité (Unique Selling Point) ?</span>
                    <input id="top-skill" type="text" name="top-skill" aria-label="top-skill" placeholder="Tournage & montage de vidéo d'entreprise" required/>
                </label>


                <label className="field half-width half-width-left" htmlFor="hourly-rate">
                <span>Quel est votre tarif horaire ?</span>
                <input id="hourly-rate" type="number" name="hourly-rate" aria-label="hourly-rate" placeholder="CHF/h" min="10" max="1000" required/>
                </label>

                <label className="field half-width half-width-right" htmlFor="daily-rate">
                <span>Quel est votre tarif journée ?</span>
                <input id="daily-rate" type="number" name="daily-rate" aria-label="daily-rate" placeholder="CHF/h" min="10" max="3000" required/>
                </label>

                <label className="field full-width" htmlFor="wish">
                    <span>Quels types de mandats vous intéressent (optionnel)</span>
                    <textarea id="wish" type="text" name="wish" aria-label="wish" placeholder="J'aimerais être cadreur pour le prochain Star Wars en Suisse romande" rows="4"></textarea>
                </label>

                <input type="hidden" name="bot-field" aria-label="bot field" />
                <input type="hidden" name="form-name"  aria-label="form name" value={formName} />

                <div>
                    <button className="send-button" type="submit" aria-label="Envoyer">{sendBtnText || "Envoyer"}</button>
                </div>
            </div>
        </form>
    )
}

export default Form
