import React from "react"

import { css } from "@emotion/core"
import { colors } from "../styles/constants"
import { GatsbyImage } from "gatsby-plugin-image"

const Blurbs = (props) => {
    const {
        numbered,
        items
    } = props

    return (
        <div css={main}>
            {items.map( (item, index) => (
                <div key={item.title} css={blurb}>
                    {item.image && <GatsbyImage image={item.image} alt={item.title} />}
                    {numbered && <div css={numbering} className="blurb__numbering">{index + 1}</div>}
                    <h3>{item.title}</h3>
                    <p>{item.desc}</p>
                </div>
            ))}
        </div>
    )
}

export default Blurbs


const main = css`
    margin: 50px 0; 
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px;

    @media screen and (max-width: 800px) {
        grid-template-columns: 1fr;
    }
`

const blurb = css`
    position: relative;
    z-index: 10;

    &:nth-of-type(5n) .blurb__numbering {
        color: ${colors.accent1};
    }
    &:nth-of-type(5n+1) .blurb__numbering {
        color: ${colors.accent2};
    }
    &:nth-of-type(5n+2) .blurb__numbering {
        color: ${colors.accent3};
    }
    &:nth-of-type(5n+3) .blurb__numbering {
        color: ${colors.accent4};
    }
    &:nth-of-type(5n+4) .blurb__numbering {
        color: ${colors.accent5};
    }

    .gatsby-image-wrapper {
        margin-bottom: 10px;
    }
`

const numbering = css`
    position: absolute;
    top: -10px;
    left: -20px;
    font-family: 'Courier Prime', monospace;
    font-size: 120px;
    font-weight: 700;
    z-index: -5;
`