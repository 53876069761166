import React from 'react'

import Layout from "../components/layout"
import Form from '../components/form'
import Seo from "../components/seo"
import Blurbs from '../components/blurbs'

// import { css } from "@emotion/core"

const benefits = [
    {
        title: "Plus de mandats",
        desc: "Multipliez le nombre de mandats auxquels vous participez et votre chiffre d'affaires",
    },
    {
        title: "Plus d'expertise",
        desc: "Développez vos compétences en relevant à chaque mandat de nouveaux défis digitaux",
    },
    {
        title: "Plus de réseau",
        desc: "Collaborez à chaque mandat avec d'autres experts digitaux en Suisse romande",
    }
]

const FreelancesPage = (props) => {

    return (
        <Layout>
            <Seo title="Freelances digitaux en Suisse romande" />

            <div className="container">
                <div className="hero-section">
                    <h1>Freelance digital en Suisse romande ?</h1>
                    <p>Vous êtes un.e freelance avec des compétences du domaine digital : production photo, tournage vidéo, développement web ou applicatif, web-design, marketing web... Nous vous permettons de trouver des mandats plus facilement en étant référencés sur notre plateforme.</p>
                </div>

                <Blurbs
                    items={benefits} 
                    numbered 
                />

                <div className="form-wrapper">
                    <h2>Rejoindre le réseau de freelances digitaux en Suisse romande</h2>

                    <Form 
                        formName="inscription-freelance"
                        freelance
                    />
                </div>
            </div>
        </Layout>
    )
}

export default FreelancesPage