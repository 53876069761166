export const colors = {
    black: "#1A1A1A",
    accent1: '#68F8C5',
    accent1_pale: '#d1feee',
    accent1_vivid: '#03FEA4',
    accent2: '#C6C4FF',
    accent2_pale: '#F4F3FF',
    accent2_vivid: '#8F8BFF',
    accent3: '#FDE270',
    accent3_pale: '#fffbe5',
    accent3_vivid: '#FFE76C',
    accent4: '#86E3FF',
    accent4_pale: '#C4F1FF',
    accent4_vivid: '#53D7FF',
    accent5: '#FFC1E0',
    accent5_pale: '#FDDDED',
    accent5_vivid: '#FF91C8',
}